@import 'antd/dist/antd.css';
@import './styles/colors.scss';
@import './styles/fonts.scss';

.row{
    margin:0px !important;
}

.cursor-pointer{
    cursor:pointer;
}

a,p,span,ul,ol,h3,h4,h5,h6{
    font-family: 'Roboto';
}

p,a,ul,ol,a.btn,button,button.btn{
    font-size: 1.125em;
}

h1{
    font-size: 3.125em;
    font-family: 'RobotoBold';
}

h2{
    font-size: 1.875em;
    font-family: 'RobotoBold';
}

h3{
    font-size: 1.75em;
    font-family: 'RobotoBold';
}

.file-drop{
    min-height: 100px;
    background: #DDD;
    text-align: center;
    padding:20px;
}

body{
    background: #DDD;
}

.text-end{
    text-align: end;
}

.text-center{
    text-align: center;
}

.login-style{
    text-align: center;
    margin-top: 100px;
    font-size: 20px;
    @media (max-width: 428px) {
        img{
            width: 100%;
        }
    }
}

/*Estilos para tablas personalizadas*/
.custom-header, .custom-footer{
    background: #EEE;
    
    div{
        padding: 10px;
        border:1px solid #DDD;
    }
}

.custom-body{
    div{
        padding: 10px;
        border:1px solid #DDD;
    }
}

/*Estilos de márgenes*/
$sizes: 12;

@mixin margin-classes {
  @for $i from 1 through $sizes {
     $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m-#{$i}  {margin: $margin;}
    .ml-#{$i} {margin-left: $margin;}
    .mr-#{$i} {margin-right: $margin;}
    .mt-#{$i} {margin-top: $margin;}
    .mb-#{$i} {margin-bottom: $margin;}
    .mx-#{$i} {margin-left: $margin; margin-right: $margin;}
    .my-#{$i} {margin-top: $margin; margin-bottom: $margin;}
  }
}
@include margin-classes;


@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p-#{$i} {padding: $padding;}
    .pl-#{$i} {padding-left: $padding;}
    .pr-#{$i} {padding-right: $padding;}
    .pt-#{$i} {padding-top: $padding;}
    .pb-#{$i} {padding-bottom: $padding;}
    .px-#{$i} {padding-left: $padding; padding-right: $padding;}
    .py-#{$i} {padding-top: $padding; padding-bottom: $padding;}
  }
}
@include padding-classes;

iframe {
    pointer-events: none;
}
